import gql from "graphql-tag";

const USER_ATTRIBUTES = gql`
    fragment UserAttributes on User {
        id
        first_name
        last_name
        email
        federal_state
        role
        phone
        department
        position
        two_factor_type
    }
`

const EXPORT_FILTER_ATTRIBUTES = gql`
    fragment ExportFilterAttributes on ExportFilter {
        before
        after
        external_id
        state
        type_rebuild
        type_alteration
        type_extension
        type_equipment
        type_renovation
        federal_state
        municipality_id
        constituency_id
        applicant
        means_from
        means_to
    }
`

export { USER_ATTRIBUTES, EXPORT_FILTER_ATTRIBUTES }