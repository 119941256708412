const bolderize = (char) => {
    let diff;
    if (/[A-Z]/.test (char))
    {
        diff = "𝗔".codePointAt (0) - "A".codePointAt (0);
    }
    else
    {
        diff = "𝗮".codePointAt (0) - "a".codePointAt (0);
    }
    return String.fromCodePoint (char.codePointAt (0) + diff);
};

const API_BASE_URL = window.CONFIG ? window.CONFIG.api_url : 'http://localhost:8000';

const guidGenerator = () => {
    let S4 = function() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

function groupBy(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
        var key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
}

export { bolderize, guidGenerator, API_BASE_URL, groupBy}