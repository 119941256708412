import 'bootstrap';
import "bootswatch/dist/lux/bootstrap.min.css";
import 'vue-select/dist/vue-select.css';

import {
  faArrowRight,
  faBorderAll,
  faBoxArchive,
  faCircleCheck,
  faCircleXmark,
  faDatabase,
  faDownload,
  faFile,
  faFileArrowUp,
  faLandmark,
  faMessage,
  faPieChart,
} from '@fortawesome/free-solid-svg-icons';
import { BAccordionItem, BAlert, BBadge, BButton, BCheck, BCollapse, BContainer, BInput, BModal, BPagination, BRange, BSelect, BSpinner, BTab, BTable, BTextarea, BToast } from '@lumaserv/bootstrap5-vue';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from 'chart.js';
import Vue, { provide } from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { DefaultApolloClient } from "@vue/apollo-composable";
import axios from "axios";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import vSelect from "vue-select";
import App from './App.vue';
import apolloClient from "./api";
import { initializeSentry } from "./lib/sentry.js";
import router from './router';

initializeSentry(Vue, router);


library.add(faCircleCheck, faFile, faPieChart, faDatabase, faBorderAll, faFileArrowUp, faDownload, faLandmark, faBoxArchive, faFile, faCircleXmark, faMessage, faArrowRight)

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.maxRedirects = 0;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.component('b-container', BContainer)
Vue.component('b-table', BTable)
Vue.component('b-button', BButton)
Vue.component('b-btn', BButton)
Vue.component('b-badge', BBadge)
Vue.component('b-alert', BAlert)
Vue.component('b-select', BSelect)
Vue.component('b-input', BInput)
Vue.component('b-check', BCheck)
Vue.component('b-range', BRange)
Vue.component('b-toast', BToast)
Vue.component('b-modal', BModal)
Vue.component('b-spinner', BSpinner)
Vue.component('b-pagination', BPagination)
Vue.component('b-tab', BTab)
Vue.component('b-accordion-item', BAccordionItem)
Vue.component('b-collapse', BCollapse)
Vue.component('b-textarea', BTextarea)
Vue.component('v-select', vSelect)
Vue.component('fai', FontAwesomeIcon)

ChartJS.register(ArcElement, BarElement, Tooltip, Legend, ChartDataLabels, CategoryScale, LinearScale)

new Vue({
  setup () {
    provide(DefaultApolloClient, apolloClient)
  },
  render: (h) => h(App),
  router,
}).$mount('#app');