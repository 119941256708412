import Vue from "vue"
import VueRouter from "vue-router"
import EventBus from "../event-bus.js";

Vue.use(VueRouter)

const defaultComponents = view => ({
    FEDERAL_STATE: view,
    STATE: view
})

const routes = [
    {
        path: '/login',
        component: () => import('../views/Login.vue'),
        name: 'login'
    },
    {
        path: '/',
        component: () => import('../components/nav/Sidebar.vue'),
        children: [
            {
                path: '',
                name: 'dashboard',
                components: {
                    FEDERAL_STATE: () => import('../views/federalState/FederalStateDashboard.vue'),
                    STATE: () => import('../views/federal/StateDashboard.vue'),
                }
            },
            {
                path: 'imprint',
                components: defaultComponents(() => import('../views/Imprint.vue')),
                name: 'imprint'
            },
            {
                path: 'privacy',
                components: defaultComponents(() => import('../views/Privacy.vue')),
                name: 'privacy'
            },
            {
                path: 'account',
                components: defaultComponents(() => import('../views/Account.vue')),
                name: 'account'
            },
            {
                path: 'reports',
                components: defaultComponents(() => import('../views/federal/Reports.vue')),
                name: 'reports'
            },
            {
                path: 'reports/:state',
                components: defaultComponents(() => import('../views/federal/Report.vue')),
                name: 'report'
            },
            {
                path: 'reviews',
                components: defaultComponents(() => import('../views/federal/review/ReviewReports.vue')),
                name: 'reviews'
            },
            {
                path: 'reviews/:report',
                components: defaultComponents(() => import('../views/federal/review/ReviewReport.vue')),
                name: 'review'
            },
            {
                path: 'reviews/:report/measures/:measure',
                components: defaultComponents(() => import('../views/federal/review/ReviewMeasure.vue')),
                name: 'review-measure'
            },
            {
                path: 'data',
                components: defaultComponents(() => import('../views/federal/Data.vue')),
                name: 'data'
            },
            {
                path: 'upload',
                name: 'upload',
                components: defaultComponents(() => import('../views/federalState/Upload.vue'))
            },
            {
                path: 'downloads',
                name: 'downloads',
                components: defaultComponents(() => import('../views/federalState/Downloads.vue'))
            },
            {
                path: 'legal',
                name: 'legal',
                components: defaultComponents(() => import('../views/federalState/Legal.vue'))
            },
            {
                path: 'archive',
                name: 'archive',
                components: defaultComponents(() => import('../views/federalState/Archive.vue'))
            },
            {
                path: 'export',
                name: 'export',
                components: defaultComponents(() => import('../views/federal/Export.vue'))
            }
        ]
    },
]

const router = new VueRouter({
    mode: "history",
    routes
})

router.beforeEach((to, from, next) => {
    if (!['account', 'login'].includes(to.name)) {
        const twoFactorType = window.localStorage.getItem('two_factor_type')
        if (twoFactorType && twoFactorType === 'NONE') {
            EventBus.$emit('add-toast', {
                variant: 'warning',
                title: 'Zwei-Faktor-Authentifizierung',
                body: 'Bevor Sie die Förderdatenbank benutzen können, müssen Sie die Zwei-Faktor-Authentifizierung einrichten.'
            })
            return next({
                name: 'account'
            })
        }
    }

    return next()
})

export default router