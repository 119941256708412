<template>
    <div class="toast-container position-absolute bottom-0 end-0 p-3">
      <div class="toast rounded-3 p-1 show" role="alert" aria-atomic="true" v-for="toast in toasts" :key="toast.id"  :class="{[`border-${toast.variant}`]: true, [`bg-${toast.variant}-subtle`]: toast.background}">
        <div class="toast-header" :class="{[`bg-${toast.variant}-subtle`]: toast.background}">
          <strong class="me-auto">{{ toast.title }}</strong>
          <button type="button" class="btn-close" v-if="toast.closeable" @click="deleteToast(toast.id)" />
        </div>
        <div class="toast-body" v-html="toast.body" />
      </div>
    </div>
</template>

<script>
import EventBus from '../event-bus'
import {guidGenerator} from '../util'

export default {
  data: () => ({
    toasts: []
  }),
  methods: {
    deleteToast(id) {
      this.toasts = this.toasts.filter(toast => toast.id !== id)
    }
  },
  mounted() {
    EventBus.$on('add-toast', (payload) => {
      let timeout = payload.timeout || 5000
      const id = guidGenerator()

      if (timeout > 0)
        setTimeout(() => this.deleteToast(id), timeout)

      this.toasts.push({
        ...payload,
        id,
        closeable: true,
        background: payload.background || false,
        createdAt: Date.now()
      })
    })
  }
}
</script>
