<template>
  <main class="h-100">
    <router-view v-if="!loading || (!user && $route.name === 'login')" @refetch="refetchUser({cache: '' + Math.random()})"></router-view>
    <b-spinner v-else></b-spinner>
    <toast-manager />
  </main>
</template>

<script>
import {useQuery} from "@vue/apollo-composable";
import gql from "graphql-tag";
import {computed, provide, watch} from "vue";
import {USER_ATTRIBUTES} from "./api/fragments.js";
import ToastManager from "./components/ToastManager.vue";
import router from "./router/index.js";

export default {
  components: {ToastManager},
  setup() {
    const {result, loading, networkStatus, refetch: refetchUser} = useQuery(gql`
        query user {
            self {
                ...UserAttributes
            }
        }
        ${USER_ATTRIBUTES}
`, null, {
      fetchPolicy: 'network-only'
    })

    const user = computed(() => result.value?.self)
    watch(user, (newUser) => {
      window.localStorage.setItem('two_factor_type', newUser.two_factor_type)
      if (newUser.id && router.currentRoute.name === 'login')
        router.push({name: 'dashboard'})
    })

    provide('user', user)
    return { user, loading, networkStatus, refetchUser }
  }
}
</script>

<style>
:root {
  --border-radius: 8px;
  --danger: #EB3535;
  --warning: #FFE751;
  --success: #5FEB3B;
}

@font-face {
  font-family: 'Bundes Sans';
  font-weight: 500;
  font-style: normal;
  font-display: auto;
  src: url("/fonts/BundesSans-Web-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Bundes Sans';
  font-weight: bold;
  src: url("/fonts/BundesSans-Web-Bold.ttf") format("truetype");
}

body {
  background-color: #F2F2F2 !important;
  height: 100vh;
}

* {
  text-transform: none !important;
  font-family: 'Bundes Sans', sans-serif;
  font-weight: normal;
  letter-spacing: normal !important;
}

.card {
  border: 0;
  border-radius: 4px;
}

.card-body {
  color: black;
}

.form-control {
  border-radius: 4px;
}

.btn {
  border-radius: 8px;
}

.btn-outline-success:disabled {
  color: var(--success);
  border-color: var(--success);
}
</style>