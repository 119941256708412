import * as Sentry from "@sentry/vue";

export function initializeSentry(app, router) {
Sentry.init({
    app,
    dsn: window.CONFIG.sentry_dsn || "https://368bdc73471cf8493939429b969115b3@errors.lumaserv.eu/26",
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: window.CONFIG.environment || "local", 
  });

}