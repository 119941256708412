import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from '@apollo/client/core'
import {API_BASE_URL} from "../util.js";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import router from "../router/index.js";
import {createUploadLink} from "apollo-upload-client";
import EventBus from "../event-bus.js";

const authLink = setContext((_, { headers }) => {
  let token = RegExp('XSRF-TOKEN[^;]+').exec(document.cookie)
  token = decodeURIComponent(token ? token.toString().replace(/^[^=]+./, '') : '')
  return {
    headers: {
      ...headers,
      'X-XSRF-TOKEN': token,
    },
  };
});

const errorLink = onError(async ({graphQLErrors, networkError}) => {
  if (networkError && networkError.statusCode === 401) {
    await router.push({name: 'login'})
  } else {
    graphQLErrors.forEach(graphQLError => {
      let body = graphQLError.message
      if (graphQLError.extensions.validation) {
        body = Object.values(graphQLError.extensions.validation).join('<br>')
      }
      EventBus.$emit('add-toast', {
        body,
        variant: 'danger',
        timeout: 10000,
        background: true
      })
    })
  }
})

const httpOptions = {
  // You should use an absolute URL here
  uri: `${API_BASE_URL}/graphql`,
  credentials: 'include',
  headers: {
    accept: 'application/json'
  }
}

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: ApolloLink.from([
      authLink,
      errorLink,
      createUploadLink(httpOptions)
  ]),
  cache,
})

export default apolloClient